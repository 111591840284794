import 'babel-polyfill'
import 'js/polyfills/customEvent'
import 'js/polyfills/closest'

//bootstrap
// import 'bootstrap/dist/js/bootstrap'

//fslighbox
require('fslightbox')
require('bootstrap-table')

// =utils
import 'js/utils/breakpoints'
import 'js/utils/detectTouch'
import 'js/utils/datePicker'
if (document.querySelector('input.-autocomplete')) {
  import(/* webpackChunkName: "autocomplete" */ 'js/utils/autocomplete')
}

// debug mode
if (document.querySelector('body').classList.contains('-debug')) {
  import(/* webpackChunkName: "debugger" */ 'js/utils/debugger')
}

if (document.querySelector('.js-copy-html')) {
  import(/* webpackChunkName: "copyHTML" */ 'js/utils/copyHTML')
}

if (document.querySelector('.js-disableonclick')) {
  import(/* webpackChunkName: "disableOnClick" */ 'js/utils/disableOnClick')
}

//===============================================================/
//  =components
//===============================================================/
if (document.querySelector('.js-table-filters')) {
  import(/* webpackChunkName: "tableFilters" */ 'js/components/table-filters')
}

if (document.querySelector('.js-ticketlist-actions')) {
  import(/* webpackChunkName: "ticketViewActions" */ 'js/components/ticketListActions')
}

if (document.querySelector('.js-ticket-view-actions')) {
  import(/* webpackChunkName: "ticketViewActions" */ 'js/components/ticketActions')
}

if (document.querySelector('.js-tooltips')) {
  import(/* webpackChunkName: "tooltips" */ 'js/components/tooltips')
}

if (document.querySelector('.js-processedemail-actions')) {
  import(/* webpackChunkName: "processedEmailActions" */ 'js/components/processedEmailActions')
}

if (document.querySelector('.js-watcher-actions')) {
  import(/* webpackChunkName: "watcherActions" */ 'js/components/watcherActions')
}

if (document.querySelector('.js-task-view-actions')) {
  import('js/components/taskActions')
}

if (document.querySelector('.js-timetrack-actions, .js-timetrack-activate')) {
  import('js/components/timetrackActions')
}

if (document.querySelector('table#forecast')) {
  import('js/components/forecastActions')
}

if (document.querySelector('table#forecastTeam')) {
  import('js/components/forecastTeamActions')
}

if (document.querySelector('table#estimates')) {
  import('js/components/estimates')
}

if (document.querySelector('table#estimateInvoices')) {
  import('js/components/estimateInvoiceActions')
}

if (document.querySelector('table#invoices')) {
  import('js/components/invoicesActions')
}

if (document.querySelector('table#deferred')) {
  import('js/components/deferredIncomeActions')
}

if (document.querySelector('table#retainersytd')) {
  import('js/components/reportRetainers')
}

if (document.querySelector('table#calendar')) {
  import('js/components/calendar')
}

if (document.querySelector('table#managejobs')) {
  import('js/components/manageJobs')
}

if (document.querySelector('.js-support-contract-actions')) {
  import('js/components/timeallocationActions')
}

if (document.querySelector('.js-update-jobnumber')) {
  import('js/components/jobActions')
}

if (document.querySelector('.js-modal')) {
  import('js/components/modal.js')
}

if (document.querySelector('.js-forecast')) {
  import('js/pages/forecast.js')
}

if (document.querySelector('#kanban')) {
  import('js/components/kanban.js')
}

if (document.querySelector('.js-estimate-actions')) {
  import('js/components/estimateActions')
}

if (document.querySelector('.js-checklist-actions')) {
  import('js/components/checklistActions')
}

if (document.querySelector('.js-outofoffice-actions')) {
  import('js/components/outofofficeActions')
}

if (document.querySelector('.js-parking-actions')) {
  import('js/components/parkingActions')
}

if (document.querySelector('.js-workday-actions')) {
  import('js/components/workdayActions')
}

//if (document.querySelector('#CustomerLoginForm_LoginForm')) {
import('js/components/login.js')
//}

if (document.querySelector('.summernote')) {
  import('js/components/wysiwyg.js')
}

if (document.querySelector('body.rooms')) {
  import('js/components/rooms.js')
}

import('js/utils/characterCount.js')

//===============================================================/
//  =blocks
//===============================================================/

//===============================================================/
//  =layout
//===============================================================/

if (document.querySelector('.js-navigation__burger')) {
  import(/* webpackChunkName: "nav" */ 'js/layouts/nav')
}

//===============================================================/
//  =pages
//===============================================================/

//===============================================================/
//  =utils
//===============================================================/

if (document.querySelector('select')) {
  import(/* webpackChunkName: "choices" */ 'js/utils/choices')
}
